import React, { useState } from 'react';
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";  
import { Box, Typography } from '../../../ui/core';
import moment from 'moment';

import { getLatestDriverLocation } from '../../../helpers/utils/driverLocation';

const style = {
  grid: {
    display: 'grid',
    gridTemplateColumns: '124px 1fr',
    rowGap: '8px',
    margin: 0,
    marginBottom: '12px',
    '> dt': {
      gridColumn: 1,
      display: 'flex',
      alignItems: 'center',
    },
    '> dd': {
      gridColumn: 2,
      display: 'flex',
      alignItems: 'center',
    }
  }
}

const DriverLocation = ({ trip }) => {
  const theme = useTheme();
  const [driverLocationDetails, setDriverLocationDetails] = useState(null);

  const latestDriverLocation = useSelector((state) => { return getLatestDriverLocation(state, trip) })

  const points = trip && trip.waypoints.map((w) => {
    const point = w[0];
    return {
      position: { lat: point.coordinates.lat, lng: point.coordinates.lon },
      type: point.type,
    }
  });

  const fetchDriverLocationDetails = async () => {
    const driverLocation = latestDriverLocation
    if (driverLocation) {
      const origin = `${driverLocation.coordinate.lat},${driverLocation.coordinate.lon}`;
      const destination = `${points[0].position.lat},${points[0].position.lng}`

      const service = new window.google.maps.DistanceMatrixService();
      service.getDistanceMatrix(
        {
          origins: [origin],
          destinations: [destination],
          travelMode: window.google.maps.TravelMode.DRIVING,
          unitSystem: window.google.maps.UnitSystem.IMPERIAL,
          drivingOptions: {
            departureTime: new Date() // needed to get duration_in_traffic
          }
        },
        (response, status) => {
          if (status === "OK") {
            const elem = response.rows[0].elements[0]
            setDriverLocationDetails({
              distance: elem.distance.text,
              duration: elem.duration_in_traffic.text,
              duration_secs: elem.duration_in_traffic.value,
              when: driverLocation.timestamp,
              location: driverLocation
            });
          } else {
            console.error("Error fetching distance:", status);
            setDriverLocationDetails(false);
          }
        }
      );
    } else {
      setDriverLocationDetails(false)
    }
  };

  const refreshLink = <a href="#" onClick={fetchDriverLocationDetails} style={{ fontSize: '16px', textDecoration: 'underline', color: theme.palette.accent.main }}>Refresh</a>

  if (driverLocationDetails === null) {
    return refreshLink
  }

  if (driverLocationDetails === false) {
    return <div>No driver location found. {refreshLink}</div>
  }

  const earliestArrival = moment().add(driverLocationDetails.duration_secs, 'seconds')
  let earliestContent;
  if (earliestArrival.isAfter(moment(trip.departureTime).subtract(20, 'minutes'))) {
    earliestContent = `${earliestArrival.format('h:mm A')} ${earliestArrival.isAfter(trip.departureTime) ? '⚠️' : ''}`
  } else {
    earliestContent = `On time`
  }

  return <>
    <Box component="dl" direction="row" sx={style.grid}>
      <dt><Typography color="text.secondary">Earliest arrival</Typography></dt>
      <dd>{earliestContent}</dd>

      <dt><Typography color="text.secondary">Drive time</Typography></dt>
      <dd>{driverLocationDetails.duration} ({driverLocationDetails.distance})</dd>

      <dt><Typography color="text.secondary">Last seen</Typography></dt>
      <dd>{moment(driverLocationDetails.when).fromNow()} @ {moment(driverLocationDetails.when).format('h:mm A')}</dd>

      <dt><Typography color="text.secondary">Type</Typography></dt>
      <dd>{driverLocationDetails.location.trip_id ? 'Foreground' : 'Background'}</dd>
    </Box>
    {refreshLink}
  </>
};

export default ({ trip }) => {
  return ( <DriverLocation trip={trip} />);
};
