/**
 * Gets latest location, if available, between foreground and background app location
 * 
 * Since this uses redux state, we return the actual object in the state instead of 
 * a new object to avoid re-renders
 * 
 * Note: the timestamp property is actually a datetime
 * 
 * Background example:
 * {
    "coordinate": {
        "lat": 29.4885473,
        "lon": -98.5920885
    },
    "timestamp": "2025-03-06T18:06:16.448Z"
}
 * 
 * Foreground example:
 * {
    "ETA": "2025-03-06T20:17:48.716Z",
    "trip_id": "67b7f9adacf8f947fb062333",
    "bearing": 351.9750061035156,
    "driver_id": "60a67974589c81001adbc9fa",
    "coordinate": {
        "lat": 31.651331175091737,
        "lon": -96.16000805366633
    },
    "timestamp": "2025-03-06T18:21:06.705Z"
}  
 */
export const getLatestDriverLocation = (state, trip) => {
  const foregroundLocation = state.drivers.locations[trip.driver?.driver_id] || null;
  const foregroundDatetime = foregroundLocation?.timestamp || '1970-01-01';

  const backgroundLocation = state.users.currentLocations[trip.driver?.id] || null;
  const backgroundDatetime = backgroundLocation?.timestamp || '1970-01-01';

  if (new Date(backgroundDatetime) < new Date(foregroundDatetime)) {
    return foregroundLocation;
  } else {
    return backgroundLocation;
  }
}