import {DriverActionsTypes as types} from "./actions";
import { DriverStatusOptions } from "../../models/Drivers";
import moment from 'moment';

const _parseDate = (v, f) => {
  if (!v) {
    return null;
  }
  const d = moment.utc(v);
  return d.isValid() ? d.format(f) : null;
};

const mapDriver = (d) => ({
  ...d,
  vehicles: (d.vehicles[0] ? d.vehicles : [{}]).map((v) => ({
    licensePlate: v.licensePlate,
    seatCount: v.seatCount,
    type: v.type,
    model: v.model,
    make: v.make,
    year: v.year,
    vin: v.vin,
    registrationImageUrl: v.registrationImageUrl,
    registrationExpDate: _parseDate(v.registrationExpDate, 'YYYY-MM-DD'),
    registrationInvalid: !!v.registrationInvalid,
    active: v.active || d.vehicles.length < 2,
  })),
  insuranceExpDate: _parseDate(d.insuranceExpDate, 'YYYY-MM-DD'),
  licenseExpDate: _parseDate(d.licenseExpDate, 'YYYY-MM-DD'),
  dob: _parseDate(d.dob, 'YYYY-MM-DD'),
  address: d.address || {},
});


const initialState = {
  all: { data: [], count: 0, loading: true },
  // TODO: we used to fetch a count of drivers in each status but this query was very inefficient
  // and causing excess cpu usage on our production mongo cluster. For now this is disabled,
  // but it could be implemented in a better way, likely by only counting drivers in
  // specific statuses we care about and also by caching that value some. leaving as part
  // of redux state in case we re-implement
  // HITCH-3224
  countPerStatus: DriverStatusOptions.reduce((d, s) => ({ ...d, [s.value]: null }), {}),
  selectedDriver: { vehicles: [] },
  error: null,
  isPending: null,
  locations: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case  types.FETCH_DRIVERS_SUCCESS:
      return {
        ...state,
        all: action.payload,
        isPending: false,
      };
    case types.FETCH_DRIVERS_STARTED:
      return {
        ...state,
        all: { ...state.all, loading: true },
        isPending: true,
      };
    case types.FETCH_DRIVERS_FAILURE:
      return {
        ...state,
        error: action.payload,
        isPending: false,
      };
    case types.FETCH_DRIVERS_COUNT_STARTED:
      return {
        ...state,
      };
    case types.FETCH_DRIVERS_COUNT_SUCCESS:
      return {
        ...state,
      };
    case types.FETCH_DRIVER_STARTED:
      return {
        ...state,
        selectedDriver: { vehicles: [] },
        isPending: false,
      };
    case types.FETCH_DRIVER_SUCCESS:
      return {
        ...state,
        selectedDriver: mapDriver(action.payload),
        isPending: false,
      };
    case types.EDIT_DRIVER_BYID_STARTED:
      return {
        ...state,
        isPending: true,
      };
    case types.EDIT_DRIVER_BYID_SUCCESS:
      return {
        ...state,
        selectedDriver: {
          ...state.selectedDriver,
          ...mapDriver(action.payload),
        },
        isPending: false
      };
    case types.EDIT_DRIVER_BYID_FAILURE:
      return {
        ...state,
        error: action.payload,
        isPending: false
      };
    case types.BACKGROUND_CHECK_STARTED:
      return {
        ...state,
        isPending: true
      };
    case types.BACKGROUND_CHECK_SUCCESS:
      return {
        ...state,
        isPending: false
      };
    case types.BACKGROUND_CHECK_FAILURE:
      return {
        ...state,
        error: action.payload,
        isPending: false
      };
    case types.DRIVER_LOCATION_UPDATE:
      const data = action.data;
      return {
        ...state,
        locations: { ...state.locations, [data.driver_id]: data },
      };
    case types.RESET_STATE:
      return initialState;
    default:
      return state;
  }
}
