import intercom from "../../../assets/intercom-logo.png";

function generateIntercomUrl(userId) {
  console.log(userId)
  const filter = {
      predicates: [
          {
              attribute: "role",
              comparison: "eq",
              type: "role",
              value: "user_role"
          },
          {
              attribute: "user_id",
              comparison: "eq",
              type: "string",
              value: userId
          }
      ]
  };
  
  // Encode the filter to Base64
  const encodedFilter = btoa(JSON.stringify(filter));
  
  // Construct the Intercom URL
  return `https://app.intercom.com/a/apps/r1x8zz6i/users/segments/active:${encodedFilter}`;
}

const UserIntercomLink = ({ userId }) => {
  return <>
    <a href={generateIntercomUrl(userId)} target="_blank" style={{ display: 'flex', alignItems: 'center' }}>
      <img height={20} src={intercom} alt="Intercom" /></a>
  </>
};

export default UserIntercomLink;