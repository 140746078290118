import React, { useEffect } from 'react';
import { useParams  } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Avatar,
  Stack,
  Paper,
  Typography,
  LinearProgress,
  Chip,
  Button,
} from '../../ui/core';
import { scrollY } from '../../ui/styles';
import { fetchUserById } from '../../redux/Users/actions';

import { RouteIcon } from '../../ui/icons';

import { DriverTypeIcon } from '../../components/widgets/DriversApproval/DriverTypeChip';
import UserDetailsCard from '../../components/widgets/Users/UserDetailsCard';
import RiderCashFlowCard from '../../components/widgets/Users/RiderCashFlowCard';
import DriverCashFlowCard from '../../components/widgets/Users/DriverCashFlowCard';
import PaymentSourcesCard from '../../components/widgets/Users/PaymentSourcesCard';
import SessionsCard from '../../components/widgets/Users/SessionsCard';
import DriverScoreCard from '../../components/widgets/Users/DriverScoreCard';
import RideHistoryCard from '../../components/widgets/Users/RideHistoryCard';
import DriveHistoryCard from '../../components/widgets/Users/DriveHistoryCard';
import PromotionsCard from '../../components/widgets/Users/PromotionsCard';
import DriverBlockDates from '../../components/widgets/Users/DriverBlockDates';
import RawDataCard from '../../components/shared/RawDataCard';
import ExperimentsCard from '../../components/widgets/Users/ExperimentsCard';
import { GrowthBook } from "@growthbook/growthbook-react";
import UserIntercomLink from '../../components/widgets/Users/UserIntercomLink';
import retoolLogo from "../../assets/retool-logo.png";

const style = {
  container: {
    height: 'calc(100vh - 148px)',
    position: 'relative',
    ...scrollY,
  },
  row: {
    '> div': {
      flex: 1,
    },
  }
};

const UserDetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const user = useSelector((state) => state.users.selection.user);
  const isUserLoaded = !!user;

  const isDriver = isUserLoaded && !!user.driver_id;

  const growthbook = new GrowthBook({
    apiHost: "https://cdn.growthbook.io",
    clientKey: "sdk-z7y8rt9V7D9qd7UN",
    subscribeToChanges: true,
  });
  growthbook.setAttributes({user_id: id})

  useEffect(() => {
    fetchUserById(id)(dispatch);
  }, [id, dispatch]);

  if (!isUserLoaded) {
    return (<Stack sx={style.container} alignItems="center">
        <LinearProgress sx={{ width: '300px', marginTop: '300px' }} />
    </Stack>);
  }

  return (<Stack sx={style.container} spacing={2}>
    <Paper variant="outlined" sx={{ padding: "12px 32px" }}>
      <Stack direction="row" spacing={3} alignItems="center" width="100%">
        <Avatar
          sx={{ height: '48px', width: '48px' }}
          alt={user.firstName}
          src={user.imageUrl || 'https://avatars1.githubusercontent.com/u/109951?s=400&v=4'}
        />
        <Typography variant="h1" noWrap>
          {user.firstName || ''} {user.lastName || ''}
        </Typography>
       {
        user.homeCity && (<Chip
          icon={<RouteIcon sx={{ fontSize: 14 }} />}
          label={`${user.homeCity.cityName}, ${user.homeCity.stateAbbr}`}
        />)
        }
        <UserIntercomLink userId={user.id} />
        <a href={`https://hitch.retool.com/apps/4d28b560-4010-11eb-a850-bf236fe3852d/User%20Details?id=${user.id}`}
           target="_blank" 
           style={{ display: 'flex', alignItems: 'center' }}>
            <img src={retoolLogo} height={20} title="Retool" />
        </a>
        {
          isDriver && <Button
            href={`/drivers-approval/funnel/${user.driver_id}`}
            sx={{ marginLeft: "auto !important" }}
          >
            <DriverTypeIcon driverType={user.driverType} isCustomDriver={user.isCustomDriver} />
            <Typography ml="8px">View driver page</Typography>
          </Button>
        }
      </Stack>
    </Paper>
    <Stack direction="row" spacing={2}>
        <Stack spacing={2} flex={1}>
          <UserDetailsCard user={user} />
          <PaymentSourcesCard user={user} />
          <PromotionsCard user={user} />
          <SessionsCard user={user} />
          <ExperimentsCard user={user} growthbook={growthbook} />
          <RawDataCard title="Raw Data" jsonObject={JSON.parse(user.userRawUnsafe.jsonString)} />
        </Stack>
        <Stack spacing={2} flex={1}>
          {user.rider_id && <RiderCashFlowCard user={user}/>}
          {user.rider_id && <RideHistoryCard user={user}/>}
          {user.driver_id && <DriverBlockDates user={user} />}
          {user.driver_id && <DriverCashFlowCard user={user}/>}
          {user.driver_id && <DriverScoreCard user={user}/>}
          { user.driver_id && <DriveHistoryCard user={user} />}
        </Stack>
    </Stack>
  </Stack>);
};

export default UserDetails;