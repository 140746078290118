import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Box,
  Menu,
  IconButton,
  Button,
  MenuItem,
  Typography,
} from '../../../../ui/core';
import { MoreVertIcon } from '../../../../ui/icons'

import UserCard from '../UserCard';
import ActionCancel from './ActionCancel';
import ActionWhitelist from './ActionWhitelist';
import ActionBlacklist from './ActionBlacklist';
import UserIntercomLink from '../../Users/UserIntercomLink';

const TripDriverField = ({ trip }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [waiting, setWaiting] = useState(false);

  const noDriverOption = { id: 'unassigned', firstName: 'No driver attached' };
  const [value, setValue] = useState(trip.driver || noDriverOption);

  useEffect(() => {
    setValue(trip.driver || noDriverOption);
  }, [trip.driver])

  const actions = [];
  if (trip.driver) {
    actions.push(<ActionCancel
      key="cancel"
      trip={trip}
      waiting={waiting}
      onWaitingChange={setWaiting}
      onClose={() => setAnchorEl(null)}
    />);
  } else {
    actions.push(<ActionWhitelist
      key="whitelist"
      trip={trip}
      onClose={() => setAnchorEl(null)}
    />);
    actions.push(<ActionBlacklist
      key="blacklist"
      trip={trip}
      onClose={() => setAnchorEl(null)}
    />);
  }


  return <Box display="flex" justifyContent="space-between" alignItems="center">
    <Button href={`/users/${value.id}`} target="_blank">
      <UserCard user={value} />
    </Button>
    {
      actions.length > 0 && <>
        <IconButton
          id="trip_drive_field_button"
          sx={{ marginLeft: "40px" }}
          aria-controls={open ? 'trip_drive_field_menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={(e) => setAnchorEl(e.currentTarget)}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="trip_drive_field_menu"
          MenuListProps={{ sx: { minWidth: 268 }, component: "div"  }}
          anchorEl={anchorEl}
          open={open}
          onClose={() => setAnchorEl(null)}
        >
          {actions}
        </Menu>
      </>
    }
    <UserIntercomLink userId={value.id} />
  </Box>
};

export default TripDriverField;
