import { useEffect, useState } from 'react';
import {
  Box,
  Stack,
  Typography,
  Skeleton,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
  ListItemIcon,
  Pagination,
  IconButton,
  Dialog,
  DialogTitle,
  TextField,
  Button,
} from '../../../ui/core';
import { ExpandMoreIcon, CashRefundIcon, CloseIcon } from '../../../ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useFormatters, useToast, useTextMasks } from '../../../hooks';
import {
  getRideHistory,
  refundRide,
} from '../../../redux/Users/actions';
import StartAvatarImg from '../../../assets/StartAvatar.png';
import { RideStatusChip } from '../Trips/RidersList';

import { useForm, useFormState } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const RefundDialog = ({ user, ride, onClose }) => {
  const t = useToast();
  const dispatch = useDispatch();
  const m = useTextMasks();

  const maxRefundAmount = ride.fare ? ride.fare.finalAmount : 0;
  const form = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      amount: `${maxRefundAmount}`,
    },
    resolver: yupResolver(
      yup.object().shape({
        amount: yup.number()
          .required("Amount is required")
          .min(0, "Refund amount must be positive")
          .max(maxRefundAmount, `Refund amount must be lower than $${maxRefundAmount}`),
      })
    )
  });
  const { isValid, isSubmitting } = useFormState({ control: form.control });

  const onSubmit = async (data) => {
    const { success } = await refundRide(user.id, ride.id, data.amount)(dispatch);
    success ? t.success(`Successfully refunded $${data.amount}`) : t.error("Something went wrong.");
    success && onClose();
  };

  return (<Dialog open onClose={onClose}>
    <Box display="flex" alignItems="center" justifyContent="space-between" padding="32px 32px 0 32px">
      <DialogTitle sx={{ padding: 0 }}>Refund Ride</DialogTitle>
      <IconButton onClick={onClose}><CloseIcon /></IconButton>
    </Box>
    <Box component="form" onSubmit={form.handleSubmit((data) => onSubmit(data))}>
      <Box padding="32px 32px 32px 32px" width="400px">
        <TextField
          name="amount"
          label="Amount"
          type="number"
          mask={m.text.number2F}
          control={form.control}
          InputProps={{
            startAdornment: "$",
          }}
          fullWidth
        />
      </Box>
      <Button
        type="submit"
        disabled={!isValid}
        loading={isSubmitting}
        variant="contained"
        size="large"
        disableElevation
        sx={{ width: "100%", padding: "20px", borderRadius: "0 0 4px 4px" }}
      >Refund</Button>
    </Box>
  </Dialog>)
};

const RideItem = ({ user, ride }) => {
  const f = useFormatters();
  const [showRefundDialog, setShowRefundDialog] = useState(false);
  const canRefund = !ride.didRefundCash;

  let route = '';
  if (ride.originStation && ride.originStation.city && ride.destinationStation && ride.destinationStation.city) {
    route = `${ride.originStation.city.cityName} to ${ride.destinationStation.city.cityName}`;
  } else if (ride.pickUp && ride.dropOff) {
    route = `${ride.pickUp.name || ride.pickUp.address} to ${ride.dropOff.name || ride.dropOff.address}`;
  }

  const openRefundDialog = () => {
    setShowRefundDialog(true);
  };

  const closeRefundDialog = () => {
    setShowRefundDialog(false);
  };

  let product = ride.product
  if (ride.isStandby && product == 'Shared') {
    product = 'Confirmed Standby'
  }

  return (<>
    <ListItem>
      <ListItemIcon>
        <img alt="avatar" height="40" width="40" src={StartAvatarImg} />
      </ListItemIcon>
      <Box display="flex" flex="1 0 0">
        <Stack spacing="4px" flex="1" marginRight="16px">
        <Box display="flex" position="relative">
            <Typography fontWeight={600}>
              {f.date.toLocaleString(ride.departureTime, f.date.DateFormats.DATETIME_MED)}
            </Typography>
            <Box position="absolute" left="200px" top="-6px">
              <RideStatusChip status={ride.status} />
            </Box>
        </Box>
          <Typography variant="body2" color="text.secondary">
            { route }
          </Typography>
        </Stack>
        <Stack spacing="4px" alignItems="flex-end">
          <Box display="flex" position="relative" paddingLeft="42px">
            {
              canRefund && <IconButton
                  size="small"
                  sx={{ position: "absolute", top: "-10px", left: 0, height: "40px", width: "40px" }}
                  onClick={openRefundDialog}
                >
                <CashRefundIcon sx={{ color: "primary.main", fontSize: "24px" }} />
              </IconButton>
            }
            <Typography fontWeight={600}>
              {ride.fare && f.number.currency(ride.fare.finalAmount)}
            </Typography>
          </Box>
          <Typography variant="body2" color="text.secondary">
            {product}
          </Typography>
        </Stack>
      </Box>
    </ListItem>
    { showRefundDialog && <RefundDialog user={user} ride={ride} onClose={closeRefundDialog} /> }
  </>);
};

const RideHistoryCard = ({ user }) => {
  const dispatch = useDispatch();
  const history = useSelector((state) => state.users.selection.rideHistory);
  const [page, setPage] = useState(1);
  const pageSize = 5;
  const pages = history ? Math.ceil(history.count / pageSize) : 0;

  const onPageChange = (_, value) => {
    setPage(value);
  };

  useEffect(() => {
    getRideHistory(user.id, (page - 1) * 5, 5)(dispatch);
  }, [page]);

  return (<Accordion variant="outlined" sx={{ padding: "0 24px"}}>
   <AccordionSummary expandIcon={<ExpandMoreIcon />} tabIndex={-1}>
      <Typography variant="subtitle1">Ride History</Typography>
   </AccordionSummary>
   <AccordionDetails sx={{ padding: '0 0 24px 0' }}>
    <Stack spacing="4px">
      {
        !history && <>
          <Skeleton variant="rectangular" height={48} />
          <Skeleton variant="rectangular" height={48} />
        </>
      }
      {
        history && <Stack>
          <List>
            {
              history.data
                .map((ride, idx) => (<RideItem
                  key={`${ride.id}-${idx}`}
                  user={user}
                  ride={ride}
                />))
            }
          </List>
         { pages > 1 &&  <Pagination count={pages} size="small" onChange={onPageChange} /> }
        </Stack>
      }
    </Stack>
   </AccordionDetails>
  </Accordion>)
};

export default RideHistoryCard;